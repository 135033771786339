let nftFrame = document.querySelector('.nft-frame')
let width = nftFrame.offsetWidth
let height = nftFrame.offsetHeight

const swiper1 = new Swiper('.swiper-press', {
  spaceBetween: 30,
  // navigation: {
  //   nextEl: '.swiper-press-next',
  //   prevEl: '.swiper-press-prev',
  // },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    dynamicBullets: true,
    clickable: true,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
  },
});

const swiper2 = new Swiper('.swiper-featured', {
  spaceBetween: 30,
  // navigation: {
  //   nextEl: '.swiper-featured-next',
  //   prevEl: '.swiper-featured-prev',
  // },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    dynamicBullets: true,
    clickable: true,
  },
  breakpoints: {
    640: {
      slidesPerView: 1,
    },
    1024: {
      slidesPerView: 2,
    },
    1280: {
      slidesPerView: 3,
    },
  },
  loop: false
});

window.addEventListener('DOMContentLoaded', (event) => {
  const swiper3 = new Swiper('.swiper-nft', {
    spaceBetween: 30,
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        autoplay: {
          delay: 5000,
        },
      },
      1024: {
        slidesPerView: 3,
        autoplay: {
          delay: 5000,
        },
      },
      1280: {
        slidesPerView: 5,
        autoplay: false,
      },
    },
  });
});

const swiper4 = new Swiper('.swiper-roadmap', {
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    dynamicBullets: true,
    clickable: true,
  },
  autoplay: {
    delay: 3000,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
    },
    1400: {
      slidesPerView: 6,
    },
  },
});

const swiper5 = new Swiper('.swiper-guild', {
  spaceBetween: 0,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    dynamicBullets: true,
    clickable: true,
  },
  autoplay: {
    delay: 5000,
  },
  slidesPerView: 1,
});

